.slick-slider {
  box-sizing: border-box;
  -webkit-user-select: none;
  user-select: none;
  -webkit-touch-callout: none;
  -khtml-user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
  display: block;
  position: relative;
}

.slick-list {
  margin: 0;
  padding: 0;
  display: block;
  position: relative;
  overflow: hidden;
}

.slick-list:focus {
  outline: none;
}

.slick-list.dragging {
  cursor: pointer;
  cursor: hand;
}

.slick-slider .slick-track, .slick-slider .slick-list {
  transform: translate3d(0, 0, 0);
}

.slick-track {
  margin-left: auto;
  margin-right: auto;
  display: block;
  position: relative;
  top: 0;
  left: 0;
}

.slick-track:before, .slick-track:after {
  content: "";
  display: table;
}

.slick-track:after {
  clear: both;
}

.slick-loading .slick-track {
  visibility: hidden;
}

.slick-slide {
  float: left;
  height: 100%;
  min-height: 1px;
  display: none;
}

[dir="rtl"] .slick-slide {
  float: right;
}

.slick-slide img {
  display: block;
}

.slick-slide.slick-loading img {
  display: none;
}

.slick-slide.dragging img {
  pointer-events: none;
}

.slick-initialized .slick-slide {
  display: block;
}

.slick-loading .slick-slide {
  visibility: hidden;
}

.slick-vertical .slick-slide {
  border: 1px solid #0000;
  height: auto;
  display: block;
}

.slick-arrow.slick-hidden {
  display: none;
}

.slick-loading .slick-list {
  background: #fff url("ajax-loader.32c19c5c.gif") center no-repeat;
}

@font-face {
  font-family: slick;
  font-weight: normal;
  font-style: normal;
  src: url("slick.019ff462.eot");
  src: url("slick.019ff462.eot#iefix") format("embedded-opentype"), url("slick.c8d05265.woff") format("woff"), url("slick.441ccdd6.ttf") format("truetype"), url("slick.0ba9c062.svg#slick") format("svg");
}

.slick-prev, .slick-next {
  cursor: pointer;
  color: #0000;
  background: none;
  border: none;
  outline: none;
  width: 20px;
  height: 20px;
  padding: 0;
  font-size: 0;
  line-height: 0;
  display: block;
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
}

.slick-prev:hover, .slick-prev:focus, .slick-next:hover, .slick-next:focus {
  color: #0000;
  background: none;
  outline: none;
}

.slick-prev:hover:before, .slick-prev:focus:before, .slick-next:hover:before, .slick-next:focus:before {
  opacity: 1;
}

.slick-prev.slick-disabled:before, .slick-next.slick-disabled:before {
  opacity: .25;
}

.slick-prev:before, .slick-next:before {
  opacity: .75;
  color: #fff;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: slick;
  font-size: 20px;
  line-height: 1;
}

.slick-prev {
  left: -25px;
}

[dir="rtl"] .slick-prev {
  left: auto;
  right: -25px;
}

.slick-prev:before {
  content: "←";
}

[dir="rtl"] .slick-prev:before {
  content: "→";
}

.slick-next {
  right: -25px;
}

[dir="rtl"] .slick-next {
  left: -25px;
  right: auto;
}

.slick-next:before {
  content: "→";
}

[dir="rtl"] .slick-next:before {
  content: "←";
}

.slick-dotted.slick-slider {
  margin-bottom: 30px;
}

.slick-dots {
  text-align: center;
  width: 100%;
  margin: 0;
  padding: 0;
  list-style: none;
  display: block;
  position: absolute;
  bottom: -25px;
}

.slick-dots li {
  cursor: pointer;
  width: 20px;
  height: 20px;
  margin: 0 5px;
  padding: 0;
  display: inline-block;
  position: relative;
}

.slick-dots li button {
  cursor: pointer;
  color: #0000;
  background: none;
  border: 0;
  outline: none;
  width: 20px;
  height: 20px;
  padding: 5px;
  font-size: 0;
  line-height: 0;
  display: block;
}

.slick-dots li button:hover, .slick-dots li button:focus {
  outline: none;
}

.slick-dots li button:hover:before, .slick-dots li button:focus:before {
  opacity: 1;
}

.slick-dots li button:before {
  content: "•";
  text-align: center;
  opacity: .25;
  color: #000;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 20px;
  height: 20px;
  font-family: slick;
  font-size: 6px;
  line-height: 20px;
  position: absolute;
  top: 0;
  left: 0;
}

.slick-dots li.slick-active button:before {
  opacity: .75;
  color: #000;
}

:root {
  --swal2-container-padding: .625em;
  --swal2-backdrop: #0006;
  --swal2-width: 32em;
  --swal2-padding: 0 0 1.25em;
  --swal2-border: none;
  --swal2-border-radius: .3125rem;
  --swal2-background: white;
  --swal2-color: #545454;
  --swal2-footer-border-color: #eee;
  --swal2-show-animation: swal2-show .3s;
  --swal2-hide-animation: swal2-hide .15s forwards;
  --swal2-input-background: transparent;
  --swal2-progress-step-background: #add8e6;
  --swal2-validation-message-background: #f0f0f0;
  --swal2-validation-message-color: #666;
  --swal2-close-button-position: initial;
  --swal2-close-button-inset: auto;
  --swal2-close-button-font-size: 2.5em;
  --swal2-close-button-color: #ccc;
}

[data-swal2-theme="dark"] {
  --swal2-dark-theme-black: #19191a;
  --swal2-dark-theme-white: #e1e1e1;
  --swal2-background: var(--swal2-dark-theme-black);
  --swal2-color: var(--swal2-dark-theme-white);
  --swal2-footer-border-color: #555;
  --swal2-input-background: color-mix(in srgb, var(--swal2-dark-theme-black), var(--swal2-dark-theme-white) 10%);
  --swal2-validation-message-background: color-mix(in srgb, var(--swal2-dark-theme-black), var(--swal2-dark-theme-white) 10%);
  --swal2-validation-message-color: var(--swal2-dark-theme-white);
}

@media (prefers-color-scheme: dark) {
  [data-swal2-theme="auto"] {
    --swal2-dark-theme-black: #19191a;
    --swal2-dark-theme-white: #e1e1e1;
    --swal2-background: var(--swal2-dark-theme-black);
    --swal2-color: var(--swal2-dark-theme-white);
    --swal2-footer-border-color: #555;
    --swal2-input-background: color-mix(in srgb, var(--swal2-dark-theme-black), var(--swal2-dark-theme-white) 10%);
    --swal2-validation-message-background: color-mix(in srgb, var(--swal2-dark-theme-black), var(--swal2-dark-theme-white) 10%);
    --swal2-validation-message-color: var(--swal2-dark-theme-white);
  }
}

body.swal2-shown:not(.swal2-no-backdrop, .swal2-toast-shown) {
  overflow: hidden;
}

body.swal2-height-auto {
  height: auto !important;
}

body.swal2-no-backdrop .swal2-container {
  pointer-events: none;
  background-color: #0000 !important;
}

body.swal2-no-backdrop .swal2-container .swal2-popup {
  pointer-events: all;
}

body.swal2-no-backdrop .swal2-container .swal2-modal {
  box-shadow: 0 0 10px var(--swal2-backdrop);
}

body.swal2-toast-shown .swal2-container {
  box-sizing: border-box;
  pointer-events: none;
  background-color: #0000;
  width: 360px;
  max-width: 100%;
}

body.swal2-toast-shown .swal2-container.swal2-top {
  inset: 0 auto auto 50%;
  transform: translateX(-50%);
}

body.swal2-toast-shown .swal2-container.swal2-top-end, body.swal2-toast-shown .swal2-container.swal2-top-right {
  inset: 0 0 auto auto;
}

body.swal2-toast-shown .swal2-container.swal2-top-start, body.swal2-toast-shown .swal2-container.swal2-top-left {
  inset: 0 auto auto 0;
}

body.swal2-toast-shown .swal2-container.swal2-center-start, body.swal2-toast-shown .swal2-container.swal2-center-left {
  inset: 50% auto auto 0;
  transform: translateY(-50%);
}

body.swal2-toast-shown .swal2-container.swal2-center {
  inset: 50% auto auto 50%;
  transform: translate(-50%, -50%);
}

body.swal2-toast-shown .swal2-container.swal2-center-end, body.swal2-toast-shown .swal2-container.swal2-center-right {
  inset: 50% 0 auto auto;
  transform: translateY(-50%);
}

body.swal2-toast-shown .swal2-container.swal2-bottom-start, body.swal2-toast-shown .swal2-container.swal2-bottom-left {
  inset: auto auto 0 0;
}

body.swal2-toast-shown .swal2-container.swal2-bottom {
  inset: auto auto 0 50%;
  transform: translateX(-50%);
}

body.swal2-toast-shown .swal2-container.swal2-bottom-end, body.swal2-toast-shown .swal2-container.swal2-bottom-right {
  inset: auto 0 0 auto;
}

@media print {
  body.swal2-shown:not(.swal2-no-backdrop, .swal2-toast-shown) {
    overflow-y: scroll !important;
  }

  body.swal2-shown:not(.swal2-no-backdrop, .swal2-toast-shown) > [aria-hidden="true"] {
    display: none;
  }

  body.swal2-shown:not(.swal2-no-backdrop, .swal2-toast-shown) .swal2-container {
    position: static !important;
  }
}

div:where(.swal2-container) {
  z-index: 1060;
  box-sizing: border-box;
  padding: var(--swal2-container-padding);
  -webkit-overflow-scrolling: touch;
  grid-template-rows: minmax(min-content, auto) minmax(min-content, auto) minmax(min-content, auto);
  grid-template-areas: "top-start top top-end"
                       "center-start center center-end"
                       "bottom-start bottom-center bottom-end";
  height: 100%;
  transition: background-color .1s;
  display: grid;
  position: fixed;
  inset: 0;
  overflow-x: hidden;
}

div:where(.swal2-container).swal2-backdrop-show, div:where(.swal2-container).swal2-noanimation {
  background: var(--swal2-backdrop);
}

div:where(.swal2-container).swal2-backdrop-hide {
  background: none !important;
}

div:where(.swal2-container).swal2-top-start, div:where(.swal2-container).swal2-center-start, div:where(.swal2-container).swal2-bottom-start {
  grid-template-columns: minmax(0, 1fr) auto auto;
}

div:where(.swal2-container).swal2-top, div:where(.swal2-container).swal2-center, div:where(.swal2-container).swal2-bottom {
  grid-template-columns: auto minmax(0, 1fr) auto;
}

div:where(.swal2-container).swal2-top-end, div:where(.swal2-container).swal2-center-end, div:where(.swal2-container).swal2-bottom-end {
  grid-template-columns: auto auto minmax(0, 1fr);
}

div:where(.swal2-container).swal2-top-start > .swal2-popup {
  align-self: start;
}

div:where(.swal2-container).swal2-top > .swal2-popup {
  grid-column: 2;
  place-self: start center;
}

div:where(.swal2-container).swal2-top-end > .swal2-popup, div:where(.swal2-container).swal2-top-right > .swal2-popup {
  grid-column: 3;
  place-self: start end;
}

div:where(.swal2-container).swal2-center-start > .swal2-popup, div:where(.swal2-container).swal2-center-left > .swal2-popup {
  grid-row: 2;
  align-self: center;
}

div:where(.swal2-container).swal2-center > .swal2-popup {
  grid-area: 2 / 2;
  place-self: center;
}

div:where(.swal2-container).swal2-center-end > .swal2-popup, div:where(.swal2-container).swal2-center-right > .swal2-popup {
  grid-area: 2 / 3;
  place-self: center end;
}

div:where(.swal2-container).swal2-bottom-start > .swal2-popup, div:where(.swal2-container).swal2-bottom-left > .swal2-popup {
  grid-area: 3 / 1;
  align-self: end;
}

div:where(.swal2-container).swal2-bottom > .swal2-popup {
  grid-area: 3 / 2;
  place-self: end center;
}

div:where(.swal2-container).swal2-bottom-end > .swal2-popup, div:where(.swal2-container).swal2-bottom-right > .swal2-popup {
  grid-area: 3 / 3;
  place-self: end;
}

div:where(.swal2-container).swal2-grow-row > .swal2-popup, div:where(.swal2-container).swal2-grow-fullscreen > .swal2-popup {
  grid-column: 1 / 4;
  width: 100%;
}

div:where(.swal2-container).swal2-grow-column > .swal2-popup, div:where(.swal2-container).swal2-grow-fullscreen > .swal2-popup {
  grid-row: 1 / 4;
  align-self: stretch;
}

div:where(.swal2-container).swal2-no-transition {
  transition: none !important;
}

div:where(.swal2-container) div:where(.swal2-popup) {
  box-sizing: border-box;
  width: var(--swal2-width);
  padding: var(--swal2-padding);
  border: var(--swal2-border);
  border-radius: var(--swal2-border-radius);
  background: var(--swal2-background);
  color: var(--swal2-color);
  grid-template-columns: minmax(0, 100%);
  max-width: 100%;
  font-family: inherit;
  font-size: 1rem;
  display: none;
  position: relative;
}

div:where(.swal2-container) div:where(.swal2-popup):focus {
  outline: none;
}

div:where(.swal2-container) div:where(.swal2-popup).swal2-loading {
  overflow-y: hidden;
}

div:where(.swal2-container) div:where(.swal2-popup).swal2-draggable, div:where(.swal2-container) div:where(.swal2-popup).swal2-draggable div:where(.swal2-icon) {
  cursor: grab;
}

div:where(.swal2-container) div:where(.swal2-popup).swal2-dragging, div:where(.swal2-container) div:where(.swal2-popup).swal2-dragging div:where(.swal2-icon) {
  cursor: grabbing;
}

div:where(.swal2-container) h2:where(.swal2-title) {
  color: inherit;
  text-align: center;
  text-transform: none;
  word-wrap: break-word;
  cursor: initial;
  max-width: 100%;
  margin: 0;
  padding: .8em 1em 0;
  font-size: 1.875em;
  font-weight: 600;
  position: relative;
}

div:where(.swal2-container) div:where(.swal2-actions) {
  z-index: 1;
  box-sizing: border-box;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  width: auto;
  margin: 1.25em auto 0;
  padding: 0;
  display: flex;
}

div:where(.swal2-container) div:where(.swal2-actions):not(.swal2-loading) .swal2-styled[disabled] {
  opacity: .4;
}

div:where(.swal2-container) div:where(.swal2-actions):not(.swal2-loading) .swal2-styled:hover {
  background-image: linear-gradient(#0000001a, #0000001a);
}

div:where(.swal2-container) div:where(.swal2-actions):not(.swal2-loading) .swal2-styled:active {
  background-image: linear-gradient(#0003, #0003);
}

div:where(.swal2-container) div:where(.swal2-loader) {
  border: .25em solid #2778c4;
  border-color: #2778c4 #0000;
  border-radius: 100%;
  justify-content: center;
  align-items: center;
  width: 2.2em;
  height: 2.2em;
  margin: 0 1.875em;
  animation: 1.5s linear infinite swal2-rotate-loading;
  display: none;
}

div:where(.swal2-container) button:where(.swal2-styled) {
  margin: .3125em;
  padding: .625em 1.1em;
  font-weight: 500;
  transition: box-shadow .1s;
  box-shadow: 0 0 0 3px #0000;
}

div:where(.swal2-container) button:where(.swal2-styled):not([disabled]) {
  cursor: pointer;
}

div:where(.swal2-container) button:where(.swal2-styled):where(.swal2-confirm) {
  background: initial;
  color: #fff;
  background-color: #7066e0;
  border: 0;
  border-radius: .25em;
  font-size: 1em;
}

div:where(.swal2-container) button:where(.swal2-styled):where(.swal2-confirm):focus-visible {
  box-shadow: 0 0 0 3px #7066e080;
}

div:where(.swal2-container) button:where(.swal2-styled):where(.swal2-deny) {
  background: initial;
  color: #fff;
  background-color: #dc3741;
  border: 0;
  border-radius: .25em;
  font-size: 1em;
}

div:where(.swal2-container) button:where(.swal2-styled):where(.swal2-deny):focus-visible {
  box-shadow: 0 0 0 3px #dc374180;
}

div:where(.swal2-container) button:where(.swal2-styled):where(.swal2-cancel) {
  background: initial;
  color: #fff;
  background-color: #6e7881;
  border: 0;
  border-radius: .25em;
  font-size: 1em;
}

div:where(.swal2-container) button:where(.swal2-styled):where(.swal2-cancel):focus-visible {
  box-shadow: 0 0 0 3px #6e788180;
}

div:where(.swal2-container) button:where(.swal2-styled).swal2-default-outline:focus-visible {
  box-shadow: 0 0 0 3px #6496c880;
}

div:where(.swal2-container) button:where(.swal2-styled):focus-visible {
  outline: none;
}

div:where(.swal2-container) button:where(.swal2-styled)::-moz-focus-inner {
  border: 0;
}

div:where(.swal2-container) div:where(.swal2-footer) {
  border-top: 1px solid var(--swal2-footer-border-color);
  color: inherit;
  text-align: center;
  cursor: initial;
  margin: 1em 0 0;
  padding: 1em 1em 0;
  font-size: 1em;
}

div:where(.swal2-container) .swal2-timer-progress-bar-container {
  border-bottom-right-radius: var(--swal2-border-radius);
  border-bottom-left-radius: var(--swal2-border-radius);
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  overflow: hidden;
  grid-column: auto !important;
}

div:where(.swal2-container) div:where(.swal2-timer-progress-bar) {
  background: #0003;
  width: 100%;
  height: .25em;
}

div:where(.swal2-container) img:where(.swal2-image) {
  cursor: initial;
  max-width: 100%;
  margin: 2em auto 1em;
}

div:where(.swal2-container) button:where(.swal2-close) {
  position: var(--swal2-close-button-position);
  inset: var(--swal2-close-button-inset);
  z-index: 2;
  border-radius: var(--swal2-border-radius);
  color: var(--swal2-close-button-color);
  font-family: monospace;
  font-size: var(--swal2-close-button-font-size);
  cursor: pointer;
  background: none;
  border: none;
  justify-content: center;
  justify-self: end;
  align-items: center;
  width: 1.2em;
  height: 1.2em;
  margin-top: 0;
  margin-bottom: -1.2em;
  margin-right: 0;
  padding: 0;
  transition: color .1s, box-shadow .1s;
  overflow: hidden;
}

div:where(.swal2-container) button:where(.swal2-close):hover {
  color: #f27474;
  background: none;
  transform: none;
}

div:where(.swal2-container) button:where(.swal2-close):focus-visible {
  outline: none;
  box-shadow: inset 0 0 0 3px #6496c880;
}

div:where(.swal2-container) button:where(.swal2-close)::-moz-focus-inner {
  border: 0;
}

div:where(.swal2-container) div:where(.swal2-html-container) {
  z-index: 1;
  color: inherit;
  text-align: center;
  word-wrap: break-word;
  word-break: break-word;
  cursor: initial;
  justify-content: center;
  margin: 0;
  padding: 1em 1.6em .3em;
  font-size: 1.125em;
  font-weight: normal;
  line-height: normal;
  overflow: auto;
}

div:where(.swal2-container) input:where(.swal2-input), div:where(.swal2-container) input:where(.swal2-file), div:where(.swal2-container) textarea:where(.swal2-textarea), div:where(.swal2-container) select:where(.swal2-select), div:where(.swal2-container) div:where(.swal2-radio), div:where(.swal2-container) label:where(.swal2-checkbox) {
  margin: 1em 2em 3px;
}

div:where(.swal2-container) input:where(.swal2-input), div:where(.swal2-container) input:where(.swal2-file), div:where(.swal2-container) textarea:where(.swal2-textarea) {
  box-sizing: border-box;
  background: var(--swal2-input-background);
  color: inherit;
  border: 1px solid #d9d9d9;
  border-radius: .1875em;
  width: auto;
  font-size: 1.125em;
  transition: border-color .1s, box-shadow .1s;
  box-shadow: inset 0 1px 1px #0000000f, 0 0 0 3px #0000;
}

div:where(.swal2-container) input:where(.swal2-input).swal2-inputerror, div:where(.swal2-container) input:where(.swal2-file).swal2-inputerror, div:where(.swal2-container) textarea:where(.swal2-textarea).swal2-inputerror {
  border-color: #f27474 !important;
  box-shadow: 0 0 2px #f27474 !important;
}

div:where(.swal2-container) input:where(.swal2-input):focus, div:where(.swal2-container) input:where(.swal2-file):focus, div:where(.swal2-container) textarea:where(.swal2-textarea):focus {
  border: 1px solid #b4dbed;
  outline: none;
  box-shadow: inset 0 1px 1px #0000000f, 0 0 0 3px #6496c880;
}

div:where(.swal2-container) input:where(.swal2-input)::placeholder, div:where(.swal2-container) input:where(.swal2-file)::placeholder, div:where(.swal2-container) textarea:where(.swal2-textarea)::placeholder {
  color: #ccc;
}

div:where(.swal2-container) .swal2-range {
  background: var(--swal2-background);
  margin: 1em 2em 3px;
}

div:where(.swal2-container) .swal2-range input {
  width: 80%;
}

div:where(.swal2-container) .swal2-range output {
  color: inherit;
  text-align: center;
  width: 20%;
  font-weight: 600;
}

div:where(.swal2-container) .swal2-range input, div:where(.swal2-container) .swal2-range output {
  height: 2.625em;
  padding: 0;
  font-size: 1.125em;
  line-height: 2.625em;
}

div:where(.swal2-container) .swal2-input {
  height: 2.625em;
  padding: 0 .75em;
}

div:where(.swal2-container) .swal2-file {
  background: var(--swal2-input-background);
  width: 75%;
  margin-left: auto;
  margin-right: auto;
  font-size: 1.125em;
}

div:where(.swal2-container) .swal2-textarea {
  height: 6.75em;
  padding: .75em;
}

div:where(.swal2-container) .swal2-select {
  background: var(--swal2-input-background);
  color: inherit;
  min-width: 50%;
  max-width: 100%;
  padding: .375em .625em;
  font-size: 1.125em;
}

div:where(.swal2-container) .swal2-radio, div:where(.swal2-container) .swal2-checkbox {
  background: var(--swal2-background);
  color: inherit;
  justify-content: center;
  align-items: center;
}

div:where(.swal2-container) .swal2-radio label, div:where(.swal2-container) .swal2-checkbox label {
  margin: 0 .6em;
  font-size: 1.125em;
}

div:where(.swal2-container) .swal2-radio input, div:where(.swal2-container) .swal2-checkbox input {
  flex-shrink: 0;
  margin: 0 .4em;
}

div:where(.swal2-container) label:where(.swal2-input-label) {
  justify-content: center;
  margin: 1em auto 0;
  display: flex;
}

div:where(.swal2-container) div:where(.swal2-validation-message) {
  background: var(--swal2-validation-message-background);
  color: var(--swal2-validation-message-color);
  justify-content: center;
  align-items: center;
  margin: 1em 0 0;
  padding: .625em;
  font-size: 1em;
  font-weight: 300;
  overflow: hidden;
}

div:where(.swal2-container) div:where(.swal2-validation-message):before {
  content: "!";
  color: #fff;
  text-align: center;
  background-color: #f27474;
  border-radius: 50%;
  width: 1.5em;
  min-width: 1.5em;
  height: 1.5em;
  margin: 0 .625em;
  font-weight: 600;
  line-height: 1.5em;
  display: inline-block;
}

div:where(.swal2-container) .swal2-progress-steps {
  background: none;
  flex-wrap: wrap;
  align-items: center;
  max-width: 100%;
  margin: 1.25em auto;
  padding: 0;
  font-weight: 600;
}

div:where(.swal2-container) .swal2-progress-steps li {
  display: inline-block;
  position: relative;
}

div:where(.swal2-container) .swal2-progress-steps .swal2-progress-step {
  z-index: 20;
  color: #fff;
  text-align: center;
  background: #2778c4;
  border-radius: 2em;
  flex-shrink: 0;
  width: 2em;
  height: 2em;
  line-height: 2em;
}

div:where(.swal2-container) .swal2-progress-steps .swal2-progress-step.swal2-active-progress-step {
  background: #2778c4;
}

div:where(.swal2-container) .swal2-progress-steps .swal2-progress-step.swal2-active-progress-step ~ .swal2-progress-step {
  background: var(--swal2-progress-step-background);
  color: #fff;
}

div:where(.swal2-container) .swal2-progress-steps .swal2-progress-step.swal2-active-progress-step ~ .swal2-progress-step-line {
  background: var(--swal2-progress-step-background);
}

div:where(.swal2-container) .swal2-progress-steps .swal2-progress-step-line {
  z-index: 10;
  background: #2778c4;
  flex-shrink: 0;
  width: 2.5em;
  height: .4em;
  margin: 0 -1px;
}

div:where(.swal2-icon) {
  box-sizing: content-box;
  cursor: default;
  -webkit-user-select: none;
  user-select: none;
  border: .25em solid #000;
  border-radius: 50%;
  justify-content: center;
  width: 5em;
  height: 5em;
  margin: 2.5em auto .6em;
  font-family: inherit;
  line-height: 5em;
  position: relative;
}

div:where(.swal2-icon) .swal2-icon-content {
  align-items: center;
  font-size: 3.75em;
  display: flex;
}

div:where(.swal2-icon).swal2-error {
  color: #f27474;
  border-color: #f27474;
}

div:where(.swal2-icon).swal2-error .swal2-x-mark {
  flex-grow: 1;
  position: relative;
}

div:where(.swal2-icon).swal2-error [class^="swal2-x-mark-line"] {
  background-color: #f27474;
  border-radius: .125em;
  width: 2.9375em;
  height: .3125em;
  display: block;
  position: absolute;
  top: 2.3125em;
}

div:where(.swal2-icon).swal2-error [class^="swal2-x-mark-line"][class$="left"] {
  left: 1.0625em;
  transform: rotate(45deg);
}

div:where(.swal2-icon).swal2-error [class^="swal2-x-mark-line"][class$="right"] {
  right: 1em;
  transform: rotate(-45deg);
}

div:where(.swal2-icon).swal2-error.swal2-icon-show {
  animation: .5s swal2-animate-error-icon;
}

div:where(.swal2-icon).swal2-error.swal2-icon-show .swal2-x-mark {
  animation: .5s swal2-animate-error-x-mark;
}

div:where(.swal2-icon).swal2-warning {
  color: #f8bb86;
  border-color: #f8bb86;
}

div:where(.swal2-icon).swal2-warning.swal2-icon-show {
  animation: .5s swal2-animate-error-icon;
}

div:where(.swal2-icon).swal2-warning.swal2-icon-show .swal2-icon-content {
  animation: .5s swal2-animate-i-mark;
}

div:where(.swal2-icon).swal2-info {
  color: #3fc3ee;
  border-color: #3fc3ee;
}

div:where(.swal2-icon).swal2-info.swal2-icon-show {
  animation: .5s swal2-animate-error-icon;
}

div:where(.swal2-icon).swal2-info.swal2-icon-show .swal2-icon-content {
  animation: .8s swal2-animate-i-mark;
}

div:where(.swal2-icon).swal2-question {
  color: #87adbd;
  border-color: #87adbd;
}

div:where(.swal2-icon).swal2-question.swal2-icon-show {
  animation: .5s swal2-animate-error-icon;
}

div:where(.swal2-icon).swal2-question.swal2-icon-show .swal2-icon-content {
  animation: .8s swal2-animate-question-mark;
}

div:where(.swal2-icon).swal2-success {
  color: #a5dc86;
  border-color: #a5dc86;
}

div:where(.swal2-icon).swal2-success [class^="swal2-success-circular-line"] {
  border-radius: 50%;
  width: 3.75em;
  height: 7.5em;
  position: absolute;
}

div:where(.swal2-icon).swal2-success [class^="swal2-success-circular-line"][class$="left"] {
  transform-origin: 3.75em 3.75em;
  border-radius: 7.5em 0 0 7.5em;
  top: -.4375em;
  left: -2.0635em;
  transform: rotate(-45deg);
}

div:where(.swal2-icon).swal2-success [class^="swal2-success-circular-line"][class$="right"] {
  transform-origin: 0 3.75em;
  border-radius: 0 7.5em 7.5em 0;
  top: -.6875em;
  left: 1.875em;
  transform: rotate(-45deg);
}

div:where(.swal2-icon).swal2-success .swal2-success-ring {
  z-index: 2;
  box-sizing: content-box;
  border: .25em solid #a5dc864d;
  border-radius: 50%;
  width: 100%;
  height: 100%;
  position: absolute;
  top: -.25em;
  left: -.25em;
}

div:where(.swal2-icon).swal2-success .swal2-success-fix {
  z-index: 1;
  width: .4375em;
  height: 5.625em;
  position: absolute;
  top: .5em;
  left: 1.625em;
  transform: rotate(-45deg);
}

div:where(.swal2-icon).swal2-success [class^="swal2-success-line"] {
  z-index: 2;
  background-color: #a5dc86;
  border-radius: .125em;
  height: .3125em;
  display: block;
  position: absolute;
}

div:where(.swal2-icon).swal2-success [class^="swal2-success-line"][class$="tip"] {
  width: 1.5625em;
  top: 2.875em;
  left: .8125em;
  transform: rotate(45deg);
}

div:where(.swal2-icon).swal2-success [class^="swal2-success-line"][class$="long"] {
  width: 2.9375em;
  top: 2.375em;
  right: .5em;
  transform: rotate(-45deg);
}

div:where(.swal2-icon).swal2-success.swal2-icon-show .swal2-success-line-tip {
  animation: .75s swal2-animate-success-line-tip;
}

div:where(.swal2-icon).swal2-success.swal2-icon-show .swal2-success-line-long {
  animation: .75s swal2-animate-success-line-long;
}

div:where(.swal2-icon).swal2-success.swal2-icon-show .swal2-success-circular-line-right {
  animation: 4.25s ease-in swal2-rotate-success-circular-line;
}

[class^="swal2"] {
  -webkit-tap-highlight-color: transparent;
}

.swal2-show {
  animation: var(--swal2-show-animation);
}

.swal2-hide {
  animation: var(--swal2-hide-animation);
}

.swal2-noanimation {
  transition: none;
}

.swal2-scrollbar-measure {
  width: 50px;
  height: 50px;
  position: absolute;
  top: -9999px;
  overflow: scroll;
}

.swal2-rtl .swal2-close {
  margin-right: initial;
  margin-left: 0;
}

.swal2-rtl .swal2-timer-progress-bar {
  left: auto;
  right: 0;
}

.swal2-toast {
  box-sizing: border-box;
  background: var(--swal2-background);
  pointer-events: all;
  grid-template-columns: min-content auto min-content;
  padding: 1em;
  overflow-y: hidden;
  box-shadow: 0 0 1px #00000013, 0 1px 2px #00000013, 1px 2px 4px #00000013, 1px 3px 8px #00000013, 2px 4px 16px #00000013;
  grid-area: 1 / 1 / 4 / 4 !important;
}

.swal2-toast > * {
  grid-column: 2;
}

.swal2-toast h2:where(.swal2-title) {
  text-align: initial;
  margin: .5em 1em;
  padding: 0;
  font-size: 1em;
}

.swal2-toast .swal2-loading {
  justify-content: center;
}

.swal2-toast input:where(.swal2-input) {
  height: 2em;
  margin: .5em;
  font-size: 1em;
}

.swal2-toast .swal2-validation-message {
  font-size: 1em;
}

.swal2-toast div:where(.swal2-footer) {
  margin: .5em 0 0;
  padding: .5em 0 0;
  font-size: .8em;
}

.swal2-toast button:where(.swal2-close) {
  grid-area: 1 / 3 / 99 / 3;
  align-self: center;
  width: .8em;
  height: .8em;
  margin: 0;
  font-size: 2em;
}

.swal2-toast div:where(.swal2-html-container) {
  overflow: initial;
  text-align: initial;
  margin: .5em 1em;
  padding: 0;
  font-size: 1em;
}

.swal2-toast div:where(.swal2-html-container):empty {
  padding: 0;
}

.swal2-toast .swal2-loader {
  grid-area: 1 / 1 / 99;
  align-self: center;
  width: 2em;
  height: 2em;
  margin: .25em;
}

.swal2-toast .swal2-icon {
  grid-area: 1 / 1 / 99;
  align-self: center;
  width: 2em;
  min-width: 2em;
  height: 2em;
  margin: 0 .5em 0 0;
}

.swal2-toast .swal2-icon .swal2-icon-content {
  align-items: center;
  font-size: 1.8em;
  font-weight: bold;
  display: flex;
}

.swal2-toast .swal2-icon.swal2-success .swal2-success-ring {
  width: 2em;
  height: 2em;
}

.swal2-toast .swal2-icon.swal2-error [class^="swal2-x-mark-line"] {
  width: 1.375em;
  top: .875em;
}

.swal2-toast .swal2-icon.swal2-error [class^="swal2-x-mark-line"][class$="left"] {
  left: .3125em;
}

.swal2-toast .swal2-icon.swal2-error [class^="swal2-x-mark-line"][class$="right"] {
  right: .3125em;
}

.swal2-toast div:where(.swal2-actions) {
  justify-content: flex-start;
  height: auto;
  margin: .5em 0 0;
  padding: 0 .5em;
}

.swal2-toast button:where(.swal2-styled) {
  margin: .25em .5em;
  padding: .4em .6em;
  font-size: 1em;
}

.swal2-toast .swal2-success {
  border-color: #a5dc86;
}

.swal2-toast .swal2-success [class^="swal2-success-circular-line"] {
  border-radius: 50%;
  width: 1.6em;
  height: 3em;
  position: absolute;
}

.swal2-toast .swal2-success [class^="swal2-success-circular-line"][class$="left"] {
  transform-origin: 2em 2em;
  border-radius: 4em 0 0 4em;
  top: -.8em;
  left: -.5em;
  transform: rotate(-45deg);
}

.swal2-toast .swal2-success [class^="swal2-success-circular-line"][class$="right"] {
  transform-origin: 0 1.5em;
  border-radius: 0 4em 4em 0;
  top: -.25em;
  left: .9375em;
}

.swal2-toast .swal2-success .swal2-success-ring {
  width: 2em;
  height: 2em;
}

.swal2-toast .swal2-success .swal2-success-fix {
  width: .4375em;
  height: 2.6875em;
  top: 0;
  left: .4375em;
}

.swal2-toast .swal2-success [class^="swal2-success-line"] {
  height: .3125em;
}

.swal2-toast .swal2-success [class^="swal2-success-line"][class$="tip"] {
  width: .75em;
  top: 1.125em;
  left: .1875em;
}

.swal2-toast .swal2-success [class^="swal2-success-line"][class$="long"] {
  width: 1.375em;
  top: .9375em;
  right: .1875em;
}

.swal2-toast .swal2-success.swal2-icon-show .swal2-success-line-tip {
  animation: .75s swal2-toast-animate-success-line-tip;
}

.swal2-toast .swal2-success.swal2-icon-show .swal2-success-line-long {
  animation: .75s swal2-toast-animate-success-line-long;
}

.swal2-toast.swal2-show {
  animation: .5s swal2-toast-show;
}

.swal2-toast.swal2-hide {
  animation: .1s forwards swal2-toast-hide;
}

@keyframes swal2-show {
  0% {
    transform: scale(.7);
  }

  45% {
    transform: scale(1.05);
  }

  80% {
    transform: scale(.95);
  }

  100% {
    transform: scale(1);
  }
}

@keyframes swal2-hide {
  0% {
    opacity: 1;
    transform: scale(1);
  }

  100% {
    opacity: 0;
    transform: scale(.5);
  }
}

@keyframes swal2-animate-success-line-tip {
  0% {
    width: 0;
    top: 1.1875em;
    left: .0625em;
  }

  54% {
    width: 0;
    top: 1.0625em;
    left: .125em;
  }

  70% {
    width: 3.125em;
    top: 2.1875em;
    left: -.375em;
  }

  84% {
    width: 1.0625em;
    top: 3em;
    left: 1.3125em;
  }

  100% {
    width: 1.5625em;
    top: 2.8125em;
    left: .8125em;
  }
}

@keyframes swal2-animate-success-line-long {
  0% {
    width: 0;
    top: 3.375em;
    right: 2.875em;
  }

  65% {
    width: 0;
    top: 3.375em;
    right: 2.875em;
  }

  84% {
    width: 3.4375em;
    top: 2.1875em;
    right: 0;
  }

  100% {
    width: 2.9375em;
    top: 2.375em;
    right: .5em;
  }
}

@keyframes swal2-rotate-success-circular-line {
  0% {
    transform: rotate(-45deg);
  }

  5% {
    transform: rotate(-45deg);
  }

  12% {
    transform: rotate(-405deg);
  }

  100% {
    transform: rotate(-405deg);
  }
}

@keyframes swal2-animate-error-x-mark {
  0% {
    opacity: 0;
    margin-top: 1.625em;
    transform: scale(.4);
  }

  50% {
    opacity: 0;
    margin-top: 1.625em;
    transform: scale(.4);
  }

  80% {
    margin-top: -.375em;
    transform: scale(1.15);
  }

  100% {
    opacity: 1;
    margin-top: 0;
    transform: scale(1);
  }
}

@keyframes swal2-animate-error-icon {
  0% {
    opacity: 0;
    transform: rotateX(100deg);
  }

  100% {
    opacity: 1;
    transform: rotateX(0);
  }
}

@keyframes swal2-rotate-loading {
  0% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(360deg);
  }
}

@keyframes swal2-animate-question-mark {
  0% {
    transform: rotateY(-360deg);
  }

  100% {
    transform: rotateY(0);
  }
}

@keyframes swal2-animate-i-mark {
  0% {
    opacity: 0;
    transform: rotateZ(45deg);
  }

  25% {
    opacity: .4;
    transform: rotateZ(-25deg);
  }

  50% {
    opacity: .8;
    transform: rotateZ(15deg);
  }

  75% {
    opacity: 1;
    transform: rotateZ(-5deg);
  }

  100% {
    opacity: 1;
    transform: rotateX(0);
  }
}

@keyframes swal2-toast-show {
  0% {
    transform: translateY(-.625em)rotateZ(2deg);
  }

  33% {
    transform: translateY(0)rotateZ(-2deg);
  }

  66% {
    transform: translateY(.3125em)rotateZ(2deg);
  }

  100% {
    transform: translateY(0)rotateZ(0);
  }
}

@keyframes swal2-toast-hide {
  100% {
    opacity: 0;
    transform: rotateZ(1deg);
  }
}

@keyframes swal2-toast-animate-success-line-tip {
  0% {
    width: 0;
    top: .5625em;
    left: .0625em;
  }

  54% {
    width: 0;
    top: .125em;
    left: .125em;
  }

  70% {
    width: 1.625em;
    top: .625em;
    left: -.25em;
  }

  84% {
    width: .5em;
    top: 1.0625em;
    left: .75em;
  }

  100% {
    width: .75em;
    top: 1.125em;
    left: .1875em;
  }
}

@keyframes swal2-toast-animate-success-line-long {
  0% {
    width: 0;
    top: 1.625em;
    right: 1.375em;
  }

  65% {
    width: 0;
    top: 1.25em;
    right: .9375em;
  }

  84% {
    width: 1.125em;
    top: .9375em;
    right: 0;
  }

  100% {
    width: 1.375em;
    top: .9375em;
    right: .1875em;
  }
}
/*# sourceMappingURL=index.4fbd9324.css.map */
